<!-- 底部栏 -->
<template>
  <div class="header">
    <ul>
      <router-link to="/">{{ $t('home') }} </router-link>
      <router-link to="/school" v-if="lang == 'zh'">
        {{ $t('schoolIntroduction') }}
      </router-link>
      <router-link to="/student">
        {{ $t('aboutUs') }}
      </router-link>
      <router-link to="/list1">
        {{ $t('events') }}
      </router-link>
      <router-link to="/job" v-if="lang == 'zh'"> {{ $t('job') }} </router-link>
      <router-link to="/shiyongxinxi" v-if="lang == 'zh'">
        {{ $t('info') }}
      </router-link>
      <router-link to="/liuyanban"> {{ $t('contactUs') }}</router-link>
      <router-link to="/hezuohuoban"> {{ $t('sponsor') }}</router-link>
      <div class="btn-box">
        <span class="btn" @click="setLang('zh')">中</span>
        <span>/</span>
        <!-- <span class="btn" @click="setLang('en')">EN</span> -->
        <!-- <span>/</span> -->
        <span class="btn" @click="setLang('de')">DE</span>
      </div>
    </ul>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import request from '@/utils/request'

// import { rowProps } from 'vant';
const header = ref('')
const headers = () => {
  request.get('generals/sanyulianjies').then((res) => {
    // console.log(res.data.data)
    header.value = res.data.data
  })
}
headers()

const lang = localStorage.getItem('lang') || 'zh'
function setLang(lang) {
  localStorage.setItem('lang', lang)
  window.location.reload()
}
</script>

<style lang="scss" scoped>
.router-link {
  padding: 0;
  margin: 0;
}

.header {
  width: 100%;
  height: 120px;
  background-color: #0d0d36;
  position: fixed;
  top: 0;
  z-index: 100;

  ul {
    width: 1200px;
    display: block;
    height: 120px;
    margin: 0 auto;
    // background: red;

    a {
      text-decoration: none;
      float: left;
      display: block;
      font-size: 16px;
      margin-right: 60px;
      line-height: 120px;
      color: #fff;
    }

    .btn-box {
      font-size: 16px;
      color: #fff;
      line-height: 120px;
      float: right;
      .btn {
        margin: 0 12px;
        cursor: pointer;
      }
    }
  }
}

// .footer {
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     width: pr(375);
//     height: pr(56);
//     background-color: #fff;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     z-index: 999;
//     margin-top: pr(20);

//     .home {
//         color: #000;
//         height: pr(56);
//         background-image: url(@/assets/images/Frame-9.png);
//         background-repeat: no-repeat;
//         background-position: center top pr(8);
//         line-height: pr(90);
//         font-size: pr(12);
//     }

//     .home1 {
//         color: #000;
//         height: pr(56);
//         background-image: url(@/assets/images/Frame-2.png);
//         background-repeat: no-repeat;
//         background-position: center top pr(8);
//         line-height: pr(90);
//         font-size: pr(12);
//     }

//     .about {
//         color: #000;
//         height: pr(56);
//         background-image: url(@/assets/images/Frame-3.png);
//         background-repeat: no-repeat;
//         background-position: center top pr(8);
//         line-height: pr(90);
//         font-size: pr(12);
//     }

//     .about1 {
//         color: #000;
//         height: pr(56);
//         background-image: url(@/assets/images/Frame-10.png);
//         background-repeat: no-repeat;
//         background-position: center top pr(8);
//         line-height: pr(90);
//         font-size: pr(12);
//     }
// }
</style>
