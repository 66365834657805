export default {
  home: 'Home',
  schoolIntroduction: 'School Introduction',
  aboutUs: 'About Us',
  events: 'Events',
  job: 'Job',
  info: 'Info',
  contactUs: 'Contact Us',
  sponsor: 'Sponsor',
  latestEvents: 'Latest Events',
  more: 'More',
  organizational: 'Organizational Structure',
  name: 'Name',
  email: 'Email',
  pleaseEnterQuestion: 'Please enter your question',
  submit: 'Submit',
  vorstand: 'Vorstand',
  exVorstand: 'Ex Vorstand',
  specialEvent: 'Special Event',
  eventList: 'Event List'
}
