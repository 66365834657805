export default {
  home: 'Startseite',
  schoolIntroduction: 'Schulinfo',
  aboutUs: 'Über uns',
  events: 'Veranstaltungen',
  job: 'Job',
  info: 'Info',
  contactUs: 'Kontakt',
  sponsor: 'Sponsor',
  latestEvents: 'Letzte Events',
  more: 'Mehr',
  organizational: 'Aufbau der Organisation',
  name: 'Name',
  email: 'Email Adresse',
  pleaseEnterQuestion: 'Bitte schreiben Sie hier',
  submit: 'Senden',
  vorstand: 'Vorstand',
  exVorstand: 'Ex Vorstand',
  specialEvent: 'Besondere Veranstaltungen',
  eventList: 'Veranstaltungsliste',
  importantNews: 'Wichtige Neuigkeiten',
  departmentIntroduction: 'Abteilung Einführung',
  departmentMember: 'Abteilungsmitglied',
  departmentEvent: 'Abteilungsveranstaltung',
  releaseTime: 'Veröffentlichungszeit'
}
