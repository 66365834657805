<!-- 首页 -->
<template>
  <div class="box5">
    <h3>{{ $t('sponsor') }}</h3>
    <div class="img-container">
      <div class="img-wrapper" v-for="item in hezuohuoban" :key="item._id"><a :href="item.herf"><img :src="item.image" alt="" /></a></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import request from '@/utils/request'
// Import Swiper Vue.js components
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
// 合作伙伴
const hezuohuoban = ref([])
const hezuohuobans = () => {
  request.get('generals/hezuohuobans?limit=30').then((res) => {
    // console.log(res.data.data)
    hezuohuoban.value = res.data.data.sort((a, b) => a.sort - b.sort)
  })
}
hezuohuobans()
</script>

<style lang="scss" scoped>
.box5 {
  width: 1200px;
  margin: 0 auto;
  max-height: 550px;
  display: table;
  clear: both;

  h3 {
    width: 1200px;
    text-align: center;
    margin: 0 auto;
    font-size: 32px;
    margin-bottom: 70px;
  }

  .img-container {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;

    .img-wrapper {
      display: inline-block;
      img {
        height: 100px; /* Set a fixed height */
        width: auto; /* Maintain aspect ratio */
      }
    }
  }
}
</style>
