import axios from 'axios'

const lang = localStorage.getItem('lang') || 'zh'
console.log(lang)

let baseURL = ''
if (lang === 'zh') {
  baseURL = process.env.VUE_APP_BASE_URL || ''
} else {
  baseURL = process.env.VUE_APP_BASE_URL_DE || ''
}

baseURL += '/api/'

console.log('baseURL', baseURL)

const request = axios.create({
  baseURL: baseURL,
  timeout: 5000
})

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token')
      // location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default request
