import { createRouter, createWebHashHistory } from 'vue-router'
import indexcn from '../views/cn/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: indexcn,
    meta: { transition: 'slide' }
  },
  {
    path: '/indexcn',
    name: 'indexcn',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/HomeView.vue')
  },
  {
    path: '/hezuohuoban',
    name: 'hezuohuoban',
    component: () =>
      import(
        /* webpackChunkName: "sponsor" */ '../views/cn/HezuoHuobanView.vue'
      )
  },
  {
    path: '/liuyanban',
    name: 'liuyanban',
    component: () =>
      import(/* webpackChunkName: "board" */ '../views/cn/LiuyanbanView.vue')
  },
  {
    path: '/newlistcont/:_id',
    name: 'newlistcont',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/NewListcont.vue')
  },
  {
    path: '/list1',
    name: 'list1',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/List1View.vue')
  },
  {
    path: '/list2/:_id',
    name: 'list2',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/List2View.vue')
  },
  {
    path: '/list3/',
    name: 'list3',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/List3View.vue')
  },

  {
    path: '/list4/',
    name: 'list4',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/List4View.vue')
  },
  {
    path: '/school',
    name: 'school',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/SchoolView.vue')
  },
  {
    path: '/schoolint/:_id',
    name: 'schoolint',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/SchoolintView.vue')
  },
  {
    path: '/student',
    name: 'student',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/StudentView.vue')
  },
  {
    path: '/bumen/:_id',
    name: 'bumen',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/StudentBumen.vue')
  },
  {
    path: '/bumenchengyuan/:_id',
    name: 'bumenchengyuan',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/StudentbmChenyuan.vue')
  },
  {
    path: '/job',
    name: 'job',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/JobView.vue')
  },
  {
    path: '/jobs/:_id',
    name: 'jobs',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/JobsView.vue')
  },
  {
    path: '/shiyongxinxi',
    name: 'shiyongxinxi',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/ShiyongxinXi.vue')
  },
  {
    path: '/shiyongcont/:_id',
    name: 'shiyongcont',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/ShiyongCont.vue')
  },
  {
    path: '/studentcont/:_id',
    name: 'studentcont',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/cn/StudentCont.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
