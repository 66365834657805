<!-- 首页 -->
<template>
  <div class="wrapper">
    <HeadView @gundong="scrollToTargetDiv(3500)" />
    <div class="banner">
      <swiper
        :spaceBetween="30"
        :effect="'fade'"
        :navigation="true"
        :pagination="{
          clickable: true
        }"
        :modules="modules"
        class="mySwiper"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false
        }"
      >
        <swiper-slide v-for="item in banner" :key="item._id">
          <div class="banner-text">
            <h3>{{ item.text1 }}</h3>
            <P>{{ item.text2 }}</P>
          </div>
          <img :src="item.image" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="box1">
      <h3>
        {{ $t('latestEvents') }}
        <router-link :to="`/list1/${ziuxin}`" class="home">
          <span>{{ $t('more') }}</span>
        </router-link>
      </h3>
      <ul>
        <li v-for="item in zuixinhuodong" :key="item._id">
          <router-link :to="`/newlistcont/${item._id}`" class="home">
            <img :src="item.image" alt="" />
            <h4>{{ item.title }}</h4>
            <p v-html="item.desc"></p>
            <div class="time">
              {{ dayjs(item.updated).format('YYYY-MM-DD HH:mm:ss') }}
            </div>
            <div class="more">{{ $t('more') }}</div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="box2">
      <h3>{{ $t('organizational') }}</h3>
      <ul>
        <li v-for="item in bumenfenlei" :key="item._id">
          <router-link :to="`/bumen/${item._id}`" class="home">
            <div class="imgs"><img :src="item.image" alt="" /></div>
            <h4>{{ item.name }}</h4>
            <p>{{ item.bumenjieshao }}</p>
            <div class="more">{{ $t('more') }}</div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="box3">
      <h3>
        {{ $t('specialEvent') }}
        <router-link :to="`/list1/${jingdian}`" class="home">
          <span>{{ $t('more') }}</span>
        </router-link>
      </h3>
      <ul>
        <li v-for="item in jingdianhuodong" :key="item._id">
          <router-link :to="`/newlistcont/${item._id}`" class="home">
            <img :src="item.image" alt="" />
            <h4>{{ item.title }}</h4>
            <p v-html="item.desc"></p>
            <div class="time">
              {{ dayjs(item.updated).format('YYYY-MM-DD HH:mm:ss') }}
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="box4">
      <div class="about">
        <!-- <p>联系我们</p> -->
        <h3>{{ $t('contactUs') }}</h3>
        <div class="namemail">
          <input
            type="text"
            name=""
            id=""
            :placeholder="$t('name')"
            class="name"
            v-model="name"
          />
          <input
            type="text"
            name=""
            id=""
            :placeholder="$t('email')"
            class="mail"
            v-model="email"
          />
        </div>
        <div class="text">
          <textarea
            type=""
            :placeholder="$t('pleaseEnterQuestion')"
            class="msg"
            v-model="msg"
          ></textarea>
        </div>
        <div class="btn" @click="liuyanbantijiao">{{ $t('submit') }}</div>
      </div>
    </div>
    <div id="zanzhushang"><HezuoHuoban /></div>
  </div>
  <FooterView />
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { showDialog } from 'vant'
import request from '@/utils/request'
import HeadView from '@/views/cn/HeadView.vue'
import FooterView from '@/views/cn/FooterView.vue'
import HezuoHuoban from '@/views/cn/HezuoHuoban.vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
import { scrollValue } from '@/composables/useChoujiang'
import dayjs from 'dayjs'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
const modules = [Autoplay, Pagination, Navigation]

// banner
const banner = ref([])
const banners = () => {
  request.get('generals/banners').then((res) => {
    // console.log(res.data.data)
    banner.value = res.data.data
  })
}
banners()

// 最新活动
const ziuxin = '6607c8583547588d906944a2'
const zuixinhuodong = ref([])
const zuixinhuodongs = () => {
  request.get(`generals/activities?suoshuhuodong=${ziuxin}`).then((res) => {
    console.log(res.data.data)
    zuixinhuodong.value = res.data.data
  })
}
zuixinhuodongs()

// 部门分类
const bumenfenlei = ref([])
const bumenfenleis = () => {
  request.get('generals/cadgories?status=true').then((res) => {
    // console.log(res.data.data)
    bumenfenlei.value = res.data.data
  })
}
bumenfenleis()

// 经典活动
const jingdian = '6607c8533547588d90694496'
const jingdianhuodong = ref([])
const jingdianhuodongs = () => {
  request.get(`generals/activities?suoshuhuodong=${jingdian}`).then((res) => {
    // console.log(res.data.data)
    jingdianhuodong.value = res.data.data
  })
}
jingdianhuodongs()

// 留言板
const name = ref('')
const email = ref('')
const msg = ref('')
const liuyanbantijiao = () => {
  console.log(name.value, email.value, msg.value)
  if (name.value === '' || email.value === '' || msg.value === '') {
    showDialog({
      message: '请填写完整信息'
    })
  } else {
    request
      .post('generals/liuyanbans', {
        name: name.value,
        email: email.value,
        content: msg.value
      })
      .then((res) => {
        console.log(res)
      })
    showDialog({
      message: '提交成功'
    })
  }
}

// 跳转指定位置
const route = useRoute()

onMounted(() => {
  // 获取查询参数
  scrollValue.value = parseInt(route.query.scroll) || 0

  // 设置页面滚动
  scrollToTargetDiv(scrollValue.value)

  // 使用 watch 监听查询参数的变化
  watch(
    () => route.query.scroll,
    (newScroll) => {
      scrollValue.value = parseInt(newScroll) || 0
      scrollToTargetDiv(scrollValue.value)
    }
  )
})

// 滚动到目标 div 的方法
const scrollToTargetDiv = (scrollValue) => {
  console.log(scrollValue)
  window.scrollTo({
    top: scrollValue,
    behavior: 'smooth'
  })
}

// 判断链接来源
// 获取URL中的scroll参数
onMounted(() => {
  // 获取URL中的scroll参数
  const scrollPosition = parseInt(window.location.href.split('scroll=')[1]) || 0

  // 判断链接来源
  const isComingFromSpecificPage = window.document.referrer.includes('indexcn')

  // 根据链接来源进行赋值
  if (!isComingFromSpecificPage) {
    scrollValue.value = scrollPosition
  }

  // 执行滚动
  window.scrollTo(0, scrollValue.value)
})
</script>

<style lang="scss" scoped>
.banner {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 780px !important;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;

    .banner-text {
      position: absolute;
      left: center;
      top: 30%;

      h3 {
        font-size: 72px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        letter-spacing: 5px;
      }

      p {
        font-size: 36px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        letter-spacing: 2px;
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.box1 {
  width: 1200px;
  margin: 0 auto;
  max-height: 1200px;
  margin-top: 120px;
  overflow: hidden;

  h3 {
    font-size: 32px;
    color: #161414;
    font-weight: 700;
    height: 45px;
    line-height: 45px;
    margin-bottom: 64px;

    span {
      width: 108px;
      height: 42px;
      background: #ebebff;
      border-radius: 6px 6px 6px 6px;
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #0d0d36;
      text-indent: 15px;
      background-image: url(@/assets/images/arrow-right-line@2x.png);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 15%;
    }
  }

  ul {
    list-style: none;

    li {
      width: 378px;
      border-radius: 16px;
      overflow: hidden;
      background: #fff;
      padding-bottom: 32px;
      margin-bottom: 32px;
      display: inline-block;
      margin-left: 32px;

      img {
        width: 378px;
        height: 292px;
      }

      h4 {
        font-size: 20px;
        margin-left: 24px;
        margin-right: 24px;
        height: 45px;
        line-height: 45px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
      }

      p {
        font-size: 14px;
        margin-left: 24px;
        margin-right: 24px;
        line-height: 25px;
        margin-top: 7px;
        height: 50px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
      }

      .time {
        font-size: 12px;
        margin-left: 24px;
        margin-right: 24px;
        line-height: 24px;
        margin-top: 16px;
        color: #333;
      }

      .more {
        width: 111px;
        font-size: 16px;
        height: 56px;
        line-height: 56px;
        text-align: center;
        color: #fff;
        background: #0d0d36;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        float: right;
        margin-right: 36px;
      }
    }

    li:nth-child(1) {
      margin-left: 0px;
    }

    li:nth-child(4) {
      margin-left: 0px;
    }
  }
}

.box2 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 120px;

  h3 {
    font-size: 32px;
    color: #161414;
    font-weight: 700;
    height: 45px;
    line-height: 45px;
    margin-bottom: 64px;

    span {
      width: 108px;
      height: 42px;
      background: #ebebff;
      border-radius: 6px 6px 6px 6px;
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #0d0d36;
      text-indent: 15px;
      background-image: url(@/assets/images/arrow-right-line@2x.png);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 15%;
    }
  }

  ul {
    list-style: none;

    li {
      width: 276px;
      height: 440px;
      border-radius: 16px;
      overflow: hidden;
      background: #fff;
      padding-bottom: 32px;
      margin-bottom: 32px;
      display: inline-block;
      margin-right: 32px;

      .imgs {
        width: 96px;
        height: 96px;
        border-radius: 100px;
        margin-top: 38px;
        margin-left: 36px;
        margin-bottom: 20px;
        background: #191957;

        img {
          width: 48px;
          height: 48px;
          display: block;
          position: relative;
          top: 24px;
          left: 24px;
        }
      }

      h4 {
        font-size: 20px;
        margin-left: 36px;
        margin-right: 36px;
        height: 45px;
        line-height: 45px;
        color: #333;
      }

      p {
        font-size: 14px;
        margin-left: 36px;
        margin-right: 36px;
        line-height: 25px;
        height: 75px;
        margin-top: 7px;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .more {
        width: 111px;
        font-size: 16px;
        line-height: 56px;
        text-align: center;
        color: #262626;
        height: 56px;
        background: #f2f4f7;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        float: right;
        margin-right: 36px;
        margin-top: 64px;
      }
    }

    li:nth-child(4n) {
      margin-right: 0px;
    }
  }
}

.box3 {
  width: 1200px;
  margin: 0 auto;
  height: 700px;
  margin-top: 120px;

  h3 {
    font-size: 32px;
    color: #161414;
    font-weight: 700;
    height: 45px;
    line-height: 45px;
    margin-bottom: 64px;

    span {
      width: 108px;
      height: 42px;
      background: #ebebff;
      border-radius: 6px 6px 6px 6px;
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #0d0d36;
      text-indent: 15px;
      background-image: url(@/assets/images/arrow-right-line@2x.png);
      background-repeat: no-repeat;
      background-position: right 10px center;
      background-size: 15%;
    }
  }

  ul {
    list-style: none;

    li {
      width: 583px;
      border-radius: 16px;
      overflow: hidden;
      background: #fff;
      padding-bottom: 32px;
      margin-bottom: 32px;
      float: left;
      margin-left: 32px;

      img {
        width: 583px;
        height: 308px;
      }

      h4 {
        font-size: 20px;
        margin-left: 24px;
        margin-right: 24px;
        height: 45px;
        line-height: 45px;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        margin-left: 24px;
        margin-right: 24px;
        line-height: 25px;
        height: 50px;
        margin-top: 7px;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time {
        font-size: 12px;
        margin-left: 24px;
        margin-right: 24px;
        line-height: 24px;
        margin-top: 16px;
        color: #333;
      }
    }

    li:nth-child(1) {
      margin-left: 0px;
    }
  }
}

.box4 {
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  background-image: url(@/assets/images/msg33@2x.png);
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 110px;

  .about {
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;

    p {
      font-size: 22px;
      color: #fff;
      margin-left: 50px;
    }

    h3 {
      font-size: 34px;
      color: #fff;
      font-weight: normal;
      margin-top: 12px;
      margin-left: 50px;
    }

    .namemail {
      width: 803px;

      .name {
        width: 380px;
        height: 48px;
        border-radius: 8px;
        font-size: 16px;
        outline: none;
        text-indent: 24px;
        border: 0;
        margin-left: 50px;
      }

      .mail {
        width: 380px;
        height: 48px;
        border-radius: 8px;
        font-size: 16px;
        outline: none;
        text-indent: 24px;
        border: 0;
        margin-left: 26px;
        margin-left: 50px;
      }
    }

    .text {
      width: 803px;
      margin-top: 32px;
      margin-left: 50px;

      .msg {
        padding: 24px;
        width: 786px;
        height: 100px;
        line-height: 30px;
        padding-top: 10px;
        border-radius: 8px;
        font-size: 16px;
        outline: none;
        border: 0;
        resize: none;
      }
    }

    .btn {
      margin-top: 15px;
      margin-left: 50px;
      width: 149px;
      height: 48px;
      line-height: 48px;
      font-size: 18px;
      color: #fff;
      text-align: center;
      background: #429346;
      border-radius: 7px 7px 7px 7px;
      cursor: pointer;
    }
  }
}
</style>
