import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
import i18n from './locales'

import 'normalize.css'
import './assets/styles/main.scss'
import 'vant/lib/index.css'

const app = createApp(App)
app.config.globalProperties.$fileURL = process.env.VUE_APP_BASE_URL
app.use(router).use(i18n).use(vant).mount('#app')
