import { createI18n } from 'vue-i18n'
import en from './en'
import zh from './zh'
import de from './de'

const messages = { en, zh, de }
const i18n = createI18n({
  locale: localStorage.getItem('lang') || 'zh',
  messages
})

export default i18n
