export default {
  home: '首页',
  schoolIntroduction: '学校介绍',
  aboutUs: '学联介绍',
  events: '活动介绍',
  job: '求职板块',
  info: '实用信息',
  contactUs: '联系我们',
  sponsor: '合作伙伴',
  latestEvents: '最新活动',
  more: '查看更多',
  organizational: '组织架构',
  name: '姓名',
  email: '邮箱',
  pleaseEnterQuestion: '请描述您的问题',
  submit: '提交',
  vorstand: '执行委员会',
  exVorstand: '历届执行委员会',
  specialEvent: '经典活动',
  eventList: '活动列表',
  importantNews: '学联大事记',
  departmentIntroduction: '部门介绍',
  departmentMember: '部门成员',
  departmentEvent: '部门活动',
  releaseTime: '发布时间'
}
