<template>
  <div class="app">
    <router-view v-slot="{ Component, route }">
      <transition :name="route.meta.transition">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup></script>

<style lang="scss">
html {
  font-size: 1vw;
}

// @media screen and (min-width: 485px) {
//   html {
//     font-size: 4.85px;
//   }
// }

* {
  margin: 0;
  padding: 0;
}

.app {
  width: 100%;
  // max-width: 485px;
  margin: 0 auto;
  font-size: pr(12);
  color: #222;
  background: #f5f7fa;
  padding-top: 120px;
}

.wrapper {
  width: 100%;
  // min-height: 100vh;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.1s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}
</style>
