<!-- 底部栏 -->
<template>
  <div class="footer">
    <div class="text1">
      {{ footer[0]?.footertext }}&nbsp;&nbsp;&nbsp;&nbsp;
      <a href="https://beian.miit.gov.cn/">{{ footer[0]?.icp }}</a>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import request from '@/utils/request'
// import { rowProps } from 'vant';
// import { useRoute } from 'vue-router'
// const route = useRoute()
const footer = ref('')
const footers = () => {
  request.get('generals/footers').then((res) => {
    // console.log(res.data.data)
    footer.value = res.data.data
  })
}
footers()
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 120px;
  background-color: #262626;
  float: left;

  .text1 {
    width: 1200px;
    margin: 0 auto;
    font-size: 14px;
    color: #fff;
    line-height: 120px;
    text-align: left;
    a {
      color: #fff;
    }
  }
}
</style>
